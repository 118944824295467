import React from 'react'
import SideBySide from '../helpers/SideBySide';

import img9 from '../images/JacobStockHouse.jpg'
import img9new from '../images/StockHome9.jpg'

function AdditionalDetails() {
    return (
        <div>
            <h2>Details</h2>
            <SideBySide
                src1={img9}
                alt1="Jacob Stock House 1863"
                src2={img9new}
                alt2="Jacob Stock House 2023"
            />
            <p>
                <strong>Dress for the Weather: </strong>
                Comfortable shoes, sunscreen, and drinking water are recommended. Depending on the season, the street temperatures can be very hot or very chilly; appropriate attire for temperature is suggested. Consult your tour guide for details on the weather forecast of ever-changing weather.
            </p>

            <p>
                <strong>Cancellation Policy: </strong>
                Reservation changes require a 24-hour notice.
            </p>

            <p>
                <strong>Payment: </strong>
                Payments upon arrival. Cash and major credit cards are accepted.
            </p>

            <p>
                <strong>Parking: </strong>
                Depending of the starting location of your tour, there are many free parking lots very near to the origin of the tour. As you make your reservation the guide will suggest the nearest free parking lot.
            </p>

            <p>
                <strong>Contributions: </strong>
                Your contribution to our cause of historic preservation is greatly appreciated.
            </p>

            <p>
                <strong>Special Needs Visitors: </strong>
                Contact your guide so we can customize a tour that best meet your needs.
            </p>

        </div>
    )
}

export default AdditionalDetails